import React from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fb } from './firebase'
import { logout } from './actions';

import { Container, Navbar, Nav, Button } from 'react-bootstrap'

const Layout = (props) => {
    const signOut = async() => {
        await fb.auth().signOut();
        props.logout();
        props.history.push("/");
    }
    return <>
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/admin/">BoxUp Support</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/admin/">Home</Nav.Link>
                        <Nav.Link href="/admin/dev">Dev Stores</Nav.Link>
                        <Nav.Link href="/admin/metafields">Metafields</Nav.Link>
                        <Nav.Link href="/admin/unwrap">Unwrap</Nav.Link>
                        {props.user.info && props.user.info.isAdmin && 
                        <Nav.Link href="/admin/users">Users</Nav.Link>
                        }
                        <Button 
                            size="sm" 
                            type="button" 
                            className="logout_btn"
                            onClick={signOut}
                        >Logout</Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container>
            {props.children}
        </Container>
    </>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Layout));