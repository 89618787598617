import { db } from './../firebase';

export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            info: null
        }
    }
}

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export function cleanMetafields(query) {
    return new Promise((resolve, reject) => {
        const { shop, builderId, token } = query;
        let endpoint = `${API_URL}/custom/clearmetafields`+
            `?shop=${shop}&id=${builderId}&token=${token}`;
        fetch(endpoint)
        .then((data) => {
            return data.json()
        })
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export const getBuilders = async(storeName) => {
    let builderList = [];
    let docs = await db.collection("Builders")
        .where("shop", "==", storeName)
        .where("deleted", "==", false)
        .get();
    if (docs.size) {
        docs.forEach(doc => builderList.push({
            "id": doc.data().builderId,
            "name": doc.data().builderName
        }));
        console.log(builderList)
        return builderList;
    } else {
        return [];
    }
}