import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fb, db } from './../firebase'
import { login, logout } from './../actions';

import { Alert, Row, Col, Button, Form, Badge } from 'react-bootstrap'

const AllowDevelopment = (props) => {

    const storeSuffix = process.env.REACT_APP_STORE_SUFFIX;
    const [loading, setLoading] = useState(false)
    const [storeSearch, setStoreSearch] = useState('')
    const [store, setStore] = useState('')

    const searchStore = async() => {
        let storeName = storeSearch;
        if (!storeName) {
            alert("Please enter a store's name (before .myshopify.com)");
            return;
        }
        setLoading(true);
        if (!storeName.includes(storeSuffix)) {
            storeName = storeSearch + storeSuffix;
        }
        let docs = await db.collection("Shops").where("shop", "==", storeName).get();
        if (docs.size > 0) {
            setStore(storeName);
            setLoading(false);
        } else {
            alert("No store exists with this name");
            setLoading(false);
        }
    }

    const addAccess = async() => {
        if (!store) alert("Please search for a store first.");
        let docs = await db.collection("Shops").where("shop", "==", store).get();
        if (docs.size > 0) {
            let storeId = '', alreadyExists = false;
            docs.forEach(doc => {
                storeId = doc.data().id;
                alreadyExists = doc.data().uninstalled !== undefined;
            });
            if (!alreadyExists) {
                await db.collection("Shops").doc(storeId).update({
                    uninstalled: false,
                    version: "3",
                    installed: true
                });
                setLoading(false);
                alert("Access added.");
            } else {
                alert("Store already has access");
            }
        }
    }

    return <div className="admin_wrapper">
        <h1>Development Store Access</h1>
        <h5><Badge bg="secondary">When to use</Badge> Someone is asking to try the app on their development store.</h5>
        <h5><Badge bg="secondary">Requires</Badge> The store must have already attempted to install the app.</h5>
        <Alert variant="primary">
            <h5>Info:</h5>
            This just updates the store's database record to show installed = true, uninstalled = false. The 
            store will not have their webhooks set up until they are on a paid plan. No webhooks means that 
        </Alert>
        <Alert variant="secondary">
            <h5>Email Template:</h5>
            <p>Hi there,</p>
            <p>I've now granted access, so you should be able to access the app from your store's 'Apps' tab.</p>
            <p>Please note, whilst you're on a development store, the automatic syncing of products will not work. Also, 
                the 'Use your own products' setting will not update the order until the store is on a paid plan. Everything else 
                will be available as normal.
            </p>
            <p>Kind regards,</p>
            <p>BoxUp Team</p>
        </Alert>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Store</Form.Label>
                <Row>
                    <Col xs={9} className="my-1">
                        <Form.Control 
                            type="text" placeholder={`xxx${storeSuffix}`} 
                            onChange={e => setStoreSearch(e.target.value)}
                            value={storeSearch}
                        />
                    </Col>
                    <Col xs={3} className="my-1">
                        {!loading ? (
                            <Button type="button" onClick={searchStore}>Search</Button>
                        ) : (
                            <Button type="button" disabled={true}>Searching...</Button>
                        )}
                    </Col>
                </Row>
            </Form.Group>
            {!loading && (
                <Button disabled={!store} variant="primary" type="button" onClick={addAccess}>
                    Add Access
                </Button>
            )}
        </Form>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllowDevelopment);