import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fb, db } from './../firebase'
import { login, logout } from './../actions';

import { Container, Row, Col, Button, Form } from 'react-bootstrap'

const Login = (props) => {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const unsubscribe = fb.auth().onAuthStateChanged((user) => {
            if (user) {
                loginUser(user);
                props.history.push("/admin/");
            } else {
              props.logout();
              props.history.push("/");
            }
        });
        return () => unsubscribe();
    }, []);

    const loginUser = async(data) => {
        let userData = await db.collection("SupportUsers").doc(data.uid).get();
        if (userData.exists) {
            let user = userData.data();
            if (user.active && email === user.email) {
                props.login(user);
            }
        } else {
            failedLogin();
        }
    }

    const failedLogin = () => {
        props.logout();
        alert("Unable to login, please try again.");
        setLoading(false);
    }

    const login = async() => {
        if (!email || !password || !email.includes("@")) {
            alert("Please enter a valid email and password");
        } else {
            setLoading(true);
            try {
                let data = await fb.auth().signInWithEmailAndPassword(email, password);
                loginUser(data.user);
            }  catch(e) {
                failedLogin();
                console.log(e.message)
            }
        }
    }

    return <div className="login_wrapper">
        <Container fluid="md">
            <Row>
                <Col></Col>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" placeholder="Enter email" 
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" placeholder="Password" 
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>
                        {loading ? (
                            <Button variant="primary" type="button">
                                Logging in...
                            </Button>
                        ) : (
                            <Button variant="primary" type="button" onClick={login}>
                                Login
                            </Button>
                        )}
                        
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));