import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { db } from './../firebase'
import { cleanMetafields, getBuilders } from './../actions'
import { login, logout } from './../actions';

import { Form, Row, Col, Badge, Button, Alert } from 'react-bootstrap'

const Admin = () => {

    const storeSuffix = process.env.REACT_APP_STORE_SUFFIX;
    const [loading, setLoading] = useState(false)
    const [allSet, setAllSet] = useState(false)
    const [storeSearch, setStoreSearch] = useState('')
    const [store, setStore] = useState('')
    const [builders, setBuilders] = useState([])
    const [builder, setBuilder] = useState('')
    const [token, setToken] = useState('')

    const searchStore = async() => {
        let storeName = storeSearch;
        if (!storeName) {
            alert("Please enter a store's name (before .myshopify.com)");
            return;
        }
        setLoading(true);
        if (!storeName.includes(storeSuffix)) {
            storeName = storeSearch + storeSuffix;
        }
        let docs = await db.collection("Shops").where("shop", "==", storeName).get();
        if (docs.size > 0) {
            setStore(storeName);
            docs.forEach(doc => {
                setToken(doc.data().access_token);
            });
            let builderList = await getBuilders(storeName);
            setBuilders(builderList);
            setLoading(false);
        } else {
            alert("No store exists with this name");
            setLoading(false);
        }
    }

    const clean = async() => {
        setLoading(true);
        if (!builder) alert("Please select a builder to clean");
        if (!store) alert("Please select a store to clean");
        let mDeleted = await cleanMetafields({
            shop: store, builderId: builder, token: token
        });
        alert(`Deleted ${mDeleted.deleted} metafields.`);
        setLoading(false);
    }

    useEffect(() => {
        setAllSet(builder && store)
    }, [builder]);

    return <div className="admin_wrapper">
        <h1>Cleaning Metafields</h1>
        <h5><Badge bg="secondary">When to use</Badge> The customers are seeing 'Cannot find variant' when adding a builder to the cart</h5>
        <h5><Badge bg="secondary">Cause</Badge> 1: The store likely manually deleted the variants not thinking much of it. 2: The 'cleanMetafields' function failed.</h5>
        <Alert variant="primary">
            <h5>Info:</h5>
            The app will store metafields on the product associated with each builder.
            When the customer adds the product to the cart, the app will check the metafields 
            to see if a variant already exists. A new variant is created if nothing exists.
        </Alert>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Store</Form.Label>
                <Row>
                    <Col xs={9} className="my-1">
                        <Form.Control 
                            type="text" placeholder={`xxx${storeSuffix}`} 
                            onChange={e => setStoreSearch(e.target.value)}
                            value={storeSearch}
                        />
                    </Col>
                    <Col xs={3} className="my-1">
                        {!loading ? (
                            <Button type="button" onClick={searchStore}>Search</Button>
                        ) : (
                            <Button type="button" disabled={true}>Searching...</Button>
                        )}
                    </Col>
                </Row>
            </Form.Group>
            {builders.length > 0 &&
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Builder to Clean</Form.Label>
                <Form.Select
                    onChange={e => setBuilder(e.target.value)}
                    value={builder}
                >
                    <option>Select builder</option>
                    {builders.map(builder => (
                        <option key={builder.id} value={builder.id}>{builder.name} ({builder.id})</option>
                    ))}
                </Form.Select>
            </Form.Group>
            }
            {loading ? (
                <Button variant="primary" type="button">
                    Cleaning...
                </Button>
            ) : (
                <Button disabled={!allSet} variant="primary" type="button" onClick={clean}>
                    Clean Variants
                </Button>
            )}
        </Form>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);