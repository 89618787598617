import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fb, db } from './../firebase'
import { login, logout, getBuilders } from './../actions';

import { Alert, Row, Col, Button, Form, Badge } from 'react-bootstrap'

const Admin = (props) => {

    const storeSuffix = process.env.REACT_APP_STORE_SUFFIX;
    const [loading, setLoading] = useState(false)
    const [storeSearch, setStoreSearch] = useState('')
    const [store, setStore] = useState('')
    const [builders, setBuilders] = useState([])
    const [builder, setBuilder] = useState('')

    const searchStore = async() => {
        let storeName = storeSearch;
        if (!storeName) {
            alert("Please enter a store's name (before .myshopify.com)");
            return;
        }
        setLoading(true);
        if (!storeName.includes(storeSuffix)) {
            storeName = storeSearch + storeSuffix;
        }
        let docs = await db.collection("Shops").where("shop", "==", storeName).get();
        if (docs.size > 0) {
            setStore(storeName);
            setLoading(false);
        } else {
            alert("No store exists with this name");
            setLoading(false);
        }
    }

    const loadBuilder = () => {
        if (!builder) {
            alert("Please select a builder first");
            return;
        }
        let url = `https://${store}/apps/builder?b=${builder}`;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const searchBuilders = async() => {
        if (!store) {
            alert("Please search for a store first.");
            return;
        }
        setLoading(true);
        let builders = await getBuilders(store);
        setBuilders(builders);
        setLoading(false);
    }

    return <div className="admin_wrapper">
        <Row>
            <Col md={6} className="my-1">
                <h1>Load a Builder</h1>
                <Alert variant="primary">
                    You can load builders by searching for the store. 
                </Alert>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Store</Form.Label>
                        <Row>
                            <Col xs={9} className="my-1">
                                <Form.Control 
                                    type="text" placeholder={`xxx${storeSuffix}`} 
                                    onChange={e => setStoreSearch(e.target.value)}
                                    value={storeSearch}
                                />
                            </Col>
                            <Col xs={3} className="my-1">
                                {!loading ? (
                                    <Button type="button" onClick={searchStore}>Search</Button>
                                ) : (
                                    <Button type="button" disabled={true}>Searching...</Button>
                                )}
                            </Col>
                        </Row>
                        {!loading && store && (
                        <Button disabled={!store} variant="primary" type="button" onClick={searchBuilders}>
                            Find Builders
                        </Button>
                        )}
                    </Form.Group>
                    {builders.length > 0 &&
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Builder to Load</Form.Label>
                        <Row>
                            <Col xs={9} className="my-1">
                                <Form.Select
                                    onChange={e => setBuilder(e.target.value)}
                                    value={builder}
                                >
                                    <option>Select builder</option>
                                    {builders.map(builder => (
                                        <option key={builder.id} value={builder.id}>{builder.name} ({builder.id})</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3}>
                                <Button type="button" onClick={loadBuilder}>Load</Button>
                            </Col>
                        </Row>
                    </Form.Group>
                    }
                    
                </Form>
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);