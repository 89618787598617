import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fb, db } from './../firebase'
import { login, logout } from './../actions';

import { Alert, Row, Col, Button, Form, Badge } from 'react-bootstrap'

const Unwrap = (props) => {

    const storeSuffix = process.env.REACT_APP_STORE_SUFFIX;
    const [loading, setLoading] = useState(false)
    const [storeSearch, setStoreSearch] = useState('')
    const [store, setStore] = useState('')

    const searchStore = async() => {
        let storeName = storeSearch;
        if (!storeName) {
            alert("Please enter a store's name (before .myshopify.com)");
            return;
        }
        setLoading(true);
        if (!storeName.includes(storeSuffix)) {
            storeName = storeSearch + storeSuffix;
        }
        let docs = await db.collection("Shops").where("shop", "==", storeName).get();
        if (docs.size > 0) {
            setStore(storeName);
            setLoading(false);
        } else {
            alert("No store exists with this name");
            setLoading(false);
        }
    }

    const addUnwrap = async() => {
        if (!store) alert("Please search for a store first.");
        let docs = await db.collection("Builders").where("shop", "==", store).get();
        if (docs.size > 0) {
            docs.forEach(async doc => {
                await db.collection("Builders").doc(doc.id).update({
                    singleProducts: true
                });
            });
            setLoading(false);
            alert("Their builders will now checkout with single products.");
        }
    }

    return <div className="admin_wrapper">
        <h1>Individual Products</h1>
        <h5><Badge bg="secondary">When to use</Badge> A store wants individual products to be added to the cart, instead of the single builder product.</h5>
        <Alert variant="primary">
            <h5>Info:</h5>
            This will also turn off the setting that edits the order. If the store also needs the customer to go straight to the checkout, they can set this in their builder's settings.
        </Alert>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Store</Form.Label>
                <Row>
                    <Col xs={9} className="my-1">
                        <Form.Control 
                            type="text" placeholder={`xxx${storeSuffix}`} 
                            onChange={e => setStoreSearch(e.target.value)}
                            value={storeSearch}
                        />
                    </Col>
                    <Col xs={3} className="my-1">
                        {!loading ? (
                            <Button type="button" onClick={searchStore}>Search</Button>
                        ) : (
                            <Button type="button" disabled={true}>Searching...</Button>
                        )}
                    </Col>
                </Row>
            </Form.Group>
            {!loading && (
                <Button disabled={!store} variant="primary" type="button" onClick={addUnwrap}>
                    Add Setting
                </Button>
            )}
        </Form>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Unwrap);