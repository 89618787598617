import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { login, logout } from './actions';

// layout routes
import Layout from './layout';
import Login from './screens/Login';
import Admin from './screens/Admin';
import Users from './screens/Users';
import Metafields from './screens/Metafields';
import AllowDevelopment from './screens/AllowDevelopment';
import Unwrap from './screens/Unwrap';

class App extends React.Component {

  requireAuth = async () => {
    return this.props.user.loggedIn;
  }

  requireAdmin = async () => {
    return this.props.user && this.props.user.isAdmin;
  }
    
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/admin/" render={() => {
            return this.requireAuth() && (
              <Layout>
                <Route exact path="/admin/" component={Admin}/>
                <Route exact path="/admin/metafields" component={Metafields}/>
                <Route exact path="/admin/dev" component={AllowDevelopment}/>
                <Route exact path="/admin/unwrap" component={Unwrap}/>
                <Route path="/admin/users" render={() => {
                  return this.requireAdmin() && (
                    <Route exact path="/admin/users" component={Users}/>
                  )
                }}/>
              </Layout>
            )
          }}/>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);