import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAwWhuTB6kt2A8yEwO9ZlfaSoeOXffgdeo",
    authDomain: "boxup-builder.firebaseapp.com",
    databaseURL: "https://boxup-builder.firebaseio.com",
    projectId: "boxup-builder",
    storageBucket: "boxup-builder.appspot.com",
    messagingSenderId: "14007275180",
    appId: "1:14007275180:web:dc413e284d29fdd4c91909",
    measurementId: "G-6FFTPZ4YDH"
};

export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const fb2 = app.initializeApp(config, "secondary");
export const db = app.firestore();
export const storage = app.storage();