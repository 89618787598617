import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fb2, db } from './../firebase'
import { login, logout } from './../actions';

import { Alert, Row, Col, Table, Badge, Button, Modal, Form } from 'react-bootstrap'

const Users = (props) => {

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [modal, setModal] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setConfirmPassword] = useState('')
    const [admin, setAdmin] = useState(false)
    const [active, setActive] = useState(true)

    const getUsers = async() => {
        if (props.user.info && props.user.info.isAdmin) {
            setLoading(true);
            let docs = await db.collection("SupportUsers").get();
            if (docs.size > 0) {
                let users = [];
                docs.forEach(doc => users.push(doc.data()));
                setUsers(users);
                setLoading(false);
            } else {
                alert("Unable to find users.");
                setLoading(false);
            }
        }
    }

    const isAdmin = (bool) => {
        return bool ? <Badge pill bg="success">Admin</Badge>
            : <Badge pill bg="danger">Non-admin</Badge>   
    }

    const isActive = (bool) => {
        return bool ? <Badge pill bg="success">Active</Badge>
            : <Badge pill bg="danger">Inactive</Badge>   
    }

    useEffect(() => {
        getUsers();
    }, [])

    const setActiveStatus = async(user) => {
        await db.collection("SupportUsers").doc(user.id).update({
            active: !user.active
        });
        getUsers();
    }

    const deleteUser = async(user) => {
        await db.collection("SupportUsers").doc(user.id).delete();
        getUsers();
    }

    const saveUser = async() => {
        setLoading(true);
        if (!email || !email.includes("@")) {
            alert("Please enter a valid email address.");
            return;
        }
        if (!password || !cpassword) {
            alert("Please enter and confirm a valid password.");
            return;
        }
        if (password !== cpassword) {
            alert("Please enter matching passwords.");
            return;
        }
        if (password.length < 8) {
            alert("Please enter a password at least 8 characters long.");
            return;
        }

        try {
            let userCredential = await fb2.auth().createUserWithEmailAndPassword(email, password);
            var newUser = userCredential.user;
            await db.collection("SupportUsers").doc(newUser.uid).set({
                email: email,
                active: active,
                isAdmin: admin,
                id: newUser.uid
            })
            getUsers();
            setLoading(false);
        } catch(e) {
            alert(e.message);
            setLoading(false);
        }
    }

    return <div className="admin_wrapper">
        
        <Row>
            <Col md={12} className="my-1">
                <h1>Users</h1>
                <Button 
                    style={{marginBottom: "10px"}}
                    type="button" 
                    onClick={() => setModal(!modal)}
                >Create User</Button>
                <Alert variant="primary">
                    Only users marked as Admin can see this page.
                </Alert>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Admin</th>
                            <th>Active</th>
                            <th>Change Status</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{isAdmin(user.isAdmin)}</td>
                                <td>{isActive(user.active)}</td>
                                <td>
                                    {!loading && <Button 
                                    size="sm"
                                    type="button" 
                                    onClick={() => setActiveStatus(user)}
                                    >{user.active ? "Deactivate" : "Activate"}</Button>
                                    }
                                </td>
                                <td>
                                    {!loading && <Button 
                                    size="sm"
                                    type="button" 
                                    variant="danger"
                                    onClick={() => deleteUser(user)}
                                    >Delete</Button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create a User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control 
                            type="email" placeholder="Enter email" 
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="password" placeholder="Password (min 8 characters)" 
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control 
                            type="password" placeholder="Confirm Password" 
                            onChange={e => setConfirmPassword(e.target.value)}
                            value={cpassword}
                        />
                    </Form.Group>
                    <Form.Check
                        inline
                        label="Active User"
                        name="group1"
                        type="checkbox"
                        id={`active`}
                        checked={active}
                        value={active}
                        onChange={() => setActive(!active)}
                    />
                    <Form.Check
                        inline
                        label="Admin User"
                        name="group1"
                        type="checkbox"
                        id={`admin`}
                        checked={admin}
                        value={admin}
                        onChange={() => setAdmin(!admin)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
                {!loading && 
                <Button variant="primary" onClick={saveUser}>
                    Save
                </Button>
                }
            </Modal.Footer>
        </Modal>
    </div>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);